import { request } from "./request"
import { BILLETWEB_EVENT_ID } from "../constants"
import { BilletwebAttendee } from "../types/billetweb"

type GetBilletwebAttendees = () => Promise<BilletwebAttendee[]>

export const getBilletwebAttendees: GetBilletwebAttendees = async () => await request<BilletwebAttendee[]>(
  `https://www.billetweb.fr/api/event/${BILLETWEB_EVENT_ID}/attendees?user=${process.env.REACT_APP_BILLETWEB_USER_ID}&key=${process.env.REACT_APP_BILLETWEB_KEY}&version=1`, {
    method: "GET",
  })

